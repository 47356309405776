import { createBrowserHistory, History } from "history";
import { applyMiddleware, compose, createStore } from "redux";
import { routerMiddleware } from "connected-react-router";
import createRootReducer from "./reducers";

export const history: History = createBrowserHistory();

export default function configureStore() { //param: preloadedState
	const store = createStore(
		createRootReducer(history), // root reducer with router state
		compose(
			applyMiddleware(
				routerMiddleware(history), // for dispatching history actions
			),
		),
	);

	return store;
}