import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { Route, Switch } from "react-router-dom";

import { ConnectedRouter } from "connected-react-router";
import { Provider } from "react-redux";
import configureStore, { history } from "./configureStore";

// import "./assets/fonts/sl-fonts/style.css";
// import "./index.scss";
// import "./scrollbars.scss";

const Desktop = React.lazy(() => import(
	// webpackChunkName: "desktop",
	// webpackPrefetch: true
	"./components/Desktop")
);

const MobileLobby = React.lazy(() => import(
	// webpackChunkName: "mobile-lobby",
	// webpackPrefetch: true
	"./components/MobileLobby")
);

const MobileGame = React.lazy(() => import(
	// webpackChunkName: "mobile-game",
	// webpackPrefetch: true
	"./components/MobileGame")
);

const ErrorPage = React.lazy(() => import(
	// webpackChunkName: "error-page",
	// webpackPrefetch: true
	"./components/ErrorPage")
);

const MaintenancePage = React.lazy(() => import(
	// webpackChunkName: "maintenance-page",
	// webpackPrefetch: true
	"./components/MaintenancePage")
);

// Debugging purposes
const NavigatorTest = React.lazy(() => import(
	// webpackChunkName: "navigator-test",
	// webpackPrefetch: true
	"./components/NavigatorTest")
);

const ScreenSizeTest = React.lazy(() => import(
	// webpackChunkName: "screen-size-test",
	// webpackPrefetch: true
	"./components/ScreenSizeTest")
);

const ImportDemo = React.lazy(() => import(
	// webpackChunkName: "import-demo",
	// webpackPrefetch: true
	"./components/ImportDemo")
);

const SwipeUpDemo = React.lazy(() => import(
	// webpackChunkName: "swipe-up-demo",
	// webpackPrefetch: true
	"./components/SwipeUpDemo")
);

const Readme = React.lazy(() => import(
	// webpackChunkName: "readme",
	// webpackPrefetch: true
	"./components/Readme")
);

const store = configureStore(); //createRootReducer

const renderLoader = <p>Loading...</p>;

class App extends React.Component {
	render() {
		// console.log("<App />", this.props);

		return (
			<Switch>
				<Route exact path="/" component={Desktop} />
				<Route path="/maintenance/:version?" component={MaintenancePage} />
				<Route path="/errors/:errorname" component={ErrorPage} />

				{ /* Mobile-only routes */}
				<Route path="/m/:game" component={MobileLobby} />
				<Route path="/m" component={MobileLobby} />
				<Route path="/play/:game" component={MobileGame} />

				{ /* Development routes */}
				<Route path="/navigator" component={NavigatorTest} />
				<Route path="/screensize" component={ScreenSizeTest} />
				<Route path="/importdemo" component={ImportDemo} />
				<Route path="/swipeupdemo" component={SwipeUpDemo} />
				<Route path="/readme/:page" component={Readme} />
				<Route path="/readme" component={Readme} />

				<Route component={ErrorPage} {...{errorType: "404"}} />
			</Switch>
		);
	}
}

ReactDOM.render((
	<Suspense fallback={renderLoader}>
		<Provider store={store}>
			<ConnectedRouter history={history}>
				<App />
			</ConnectedRouter>
		</Provider>
	</Suspense>
), document.getElementById("root"));
